import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCheckoutForm from "./paymentcheckout";
import { REACT_APP_STRIPE_PUBLISHABLE_KEY } from "../../config";

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PaymentDetail({getAllSaveCard, openModalflag,close, selectedPlanId, setSelectedPlanId}) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentCheckoutForm selectedPlanId={selectedPlanId} setSelectedPlanId={setSelectedPlanId} getAllSaveCard={getAllSaveCard} openModalflag={openModalflag} close={close}/>
    </Elements>
  );
}
