import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { SketchPicker } from "react-color";

const ColorPickerModal = (props) => {
  const [currentColor, setCurrentColor] = useState("#263682");

  const handleChangeColor = (color) => {
    setCurrentColor(color.hex);
  };
  const handleClosePicker = () => {
    props?.onCloseModal();
  };
  return (
    <>
      <SketchPicker color={currentColor} onChangeComplete={handleChangeColor} />
      <div className="d-flex align-items-center justify-content-end mt-3">
        <button className="colorPickerBtn" onClick={handleClosePicker}>
          <i className="fa-solid fa-xmark colorPicker_CrossIcon"></i>
        </button>
        <button className="colorPickerBtn colorPickerSelectBtn ms-2">
          <i className="fa-solid fa-check colorPicker_CheckIcon"></i>
        </button>
      </div>
    </>
  );
};

export default ColorPickerModal;
