if (process.env.NODE_ENV === "production") {

  module.exports = {
    // // Development Server
    // REACT_APP_PRODUCT_URL: "https://apiproductmgmt.jobr.com/",
    // REACT_APP_WALLET_URL: "https://apiwallet.jobr.com/",
    // REACT_APP_USER_SERVICES: "https://apiuserservice.jobr.com/",
    // REACT_APP_ORDER_URL: "https://apiorder.jobr.com:8004/",
    // REACT_APP_CHAT_URL: "https://apichat.jobr.com:8007/",
    // REACT_APP_SUPPORT_URL: "https://apisupport.jobr.com/",
    // REACT_APP_STRIPE_PUBLISHABLE_KEY: "pk_test_51Ns4VYHc9FMcd0k2V48q1pcjYCIIdjdTi21aJM48H5tXN0jxZU5tAjWy3rzuy1fSq847qwk54drtzqQ3eVFZr0uA00l5i5PDzZ"

    // Staging Server
    REACT_APP_PRODUCT_URL: "https://staging-apiproductmgmt.jobr.com/",
    REACT_APP_WALLET_URL: "https://staging-apiwallet.jobr.com/",
    REACT_APP_USER_SERVICES: "https://staging-apiuserservice.jobr.com/",
    REACT_APP_ORDER_URL: "https://staging-apiorder.jobr.com:8055/",
    REACT_APP_CHAT_URL: "https://staging-apichat.jobr.com:8056/",
    REACT_APP_SUPPORT_URL: "https://staging-apisupport.jobr.com/",
    REACT_APP_STRIPE_PUBLISHABLE_KEY: "pk_test_51Ns4VYHc9FMcd0k2V48q1pcjYCIIdjdTi21aJM48H5tXN0jxZU5tAjWy3rzuy1fSq847qwk54drtzqQ3eVFZr0uA00l5i5PDzZ"
  };

} else {

  module.exports = {
    // // Development Server
    // REACT_APP_PRODUCT_URL: "https://apiproductmgmt.jobr.com/",
    // REACT_APP_WALLET_URL: "https://apiwallet.jobr.com/",
    // REACT_APP_USER_SERVICES: "https://apiuserservice.jobr.com/",
    // REACT_APP_ORDER_URL: "https://apiorder.jobr.com:8004/",
    // REACT_APP_CHAT_URL: "https://apichat.jobr.com:8007/",
    // REACT_APP_SUPPORT_URL: "https://apisupport.jobr.com/",
    // REACT_APP_STRIPE_PUBLISHABLE_KEY: "pk_test_51Ns4VYHc9FMcd0k2V48q1pcjYCIIdjdTi21aJM48H5tXN0jxZU5tAjWy3rzuy1fSq847qwk54drtzqQ3eVFZr0uA00l5i5PDzZ"

    // Staging Server
    REACT_APP_PRODUCT_URL: "https://staging-apiproductmgmt.jobr.com/",
    REACT_APP_WALLET_URL: "https://staging-apiwallet.jobr.com/",
    REACT_APP_USER_SERVICES: "https://staging-apiuserservice.jobr.com/",
    REACT_APP_ORDER_URL: "https://staging-apiorder.jobr.com:8055/",
    REACT_APP_CHAT_URL: "https://staging-apichat.jobr.com:8056/",
    REACT_APP_SUPPORT_URL: "https://staging-apisupport.jobr.com/",
    REACT_APP_STRIPE_PUBLISHABLE_KEY: "pk_test_51Ns4VYHc9FMcd0k2V48q1pcjYCIIdjdTi21aJM48H5tXN0jxZU5tAjWy3rzuy1fSq847qwk54drtzqQ3eVFZr0uA00l5i5PDzZ"
  };
}