import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { CKEditor } from "ckeditor4-react";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import Select from "react-select";

const ProductDetails = (props) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [typedValue, setTypedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [services, setServices] = useState([]);

  const [servicesId, setServicesId] = useState("");
  const [category, setCategory] = useState([]);
  console.log(category, "selectedCategory");
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubcategoryId] = useState("");
  const [brand, setBrand] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const dispatch = useDispatch();
  let productResponse;

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setSelectedService(e);
    setServicesId(e.value);
    props?.setServiceIdforAttributes(e.value);
    setSelectedSubCategory("");
    setSelectedBrand("");
    setSelectedCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true);
    setSelectedCategory(e);
    setCategoryId(e.value);
    setSubCategory([]);
    setSubcategoryId("");
    setSelectedSubCategory("");
    setSelectedBrand("");
    setBrand([]);
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        // main_category: true,
        need_subcategory: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: e.value,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setBrandLoading(true);
    setSelectedSubCategory(e);
    setSubcategoryId(e.value);
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let brandData = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        check_product_existance: false,
      },
    };
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setBrand(filterResult);
        setBrandLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: categoryId,
          sub_category_id: e.value,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleBrandChange = (e) => {
    setBrandLoading(true);
    setSelectedBrand(e);
    productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: e.value,
    };
    props.productDetails(productResponse, "");
    setBrandId(e.value);
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
    productResponse = {
      productName: e.target.value,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
    };
    props.productDetails(productResponse, "");
  };
  const handleProductDescription = (e) => {
    props.productDetails(e, "description");
    setProductDescription(e);
  };

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = brand?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (brand?.length > 0) {
            setBrand([...brand, newOption]);
          } else {
            setBrand([newOption]);
          }
        }
      }
    }
  };

  const handleKeyDownSubcategory = (event) => {
    console.log("called");
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          } else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    props.setSelectedService(selectedService);
  }, [selectedService]);
  return (
    <>
      <div className="productDetailsContainer">
        {/* Start ProductName Area */}
        <h2 className="fontSize18 fw500 txtDarkblue">Product Details</h2>
        <form className="productName  ">
          <div className="row">
            <div className="col-md-12 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Product Name
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Product name"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Barcode (ISBN, UPC, GTIN, etc.)
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Select one"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  UPC (Unique product code)
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Select one"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Product weight
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Select one"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
                <p className="messageTxt ps-4">
                  This information is needed for shipping items
                </p>
              </div>
            </div>
            <div className="col-md-6 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Product Dimensions
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Select one"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
                <p className="messageTxt ps-4">
                  This information is needed for shipping items
                </p>
              </div>
            </div>
          </div>

         
        </form>
        {/* End ProductName Area */}
        {/* Start ProductCategory Area */}
        <form className="ProductCategory mt-3 mb-4">
          <h2 className="fontSize18 fw500 txtDarkblue mb-0">
            Product Category
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                  Master Category
                </label>

                <Select
                  value={selectedService}
                  className="customSelect"
                  onChange={handleServicesChange}
                  options={services}
                  isSearchable={true}
                  placeholder="Select one"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                  Category
                </label>

                <Select
                  value={selectedCategory}
                  className="customSelect"
                  onChange={handleCategoryChange}
                  options={category}
                  isDisabled={!servicesId}
                  isSearchable={true}
                  placeholder={
                    category && categoryLoading
                      ? "Loading Categories..."
                      : "Select one"
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                  Subcategory
                </label>

                <Select
                  value={selectedSubCategory}
                  className="customSelect"
                  onChange={handleSubCategoryChange}
                  onKeyDown={handleKeyDownSubcategory}
                  options={subCategory}
                  isDisabled={!categoryId}
                  isSearchable={true}
                  placeholder={
                    subCategory && subCategoryLoading
                      ? "Loading Categories..."
                      : "Select one"
                  }
                  // placeholder="Select Sub Category"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                  Brand
                </label>

                {/* <select className='customform-control select bg-white' defaultValue={"Select Brand"} disabled={!subCategoryId} onChange={(e) => handleBrandChange(e)}>
                                <option value="Select Brand" >Select Brand</option>
                                {
                                    (brand) ?
                                        <>
                                            {brand?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No Brand Found">No Brand Found</option>
                                }
                            </select> */}
                <Select
                  value={selectedBrand}
                  className="customSelect"
                  onChange={handleBrandChange}
                  onKeyDown={handleKeyDown}
                  options={brand}
                  isDisabled={!subCategoryId}
                  isSearchable={true}
                  placeholder={
                    brand && brandLoading ? "Loading Brand..." : "Select one"
                  }
                  // placeholder="Select Brand"
                />
              </div>
            </div>
          </div>
        </form>
        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div className="productDetailsSpc">
          <CKEditor
            initData={productDescription}
            config={{
              versionCheck: false,
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "image",
              ],
            }}
            name="editor"
            readOnly={false}
            onChange={(event, editor) => {
              const data = event.editor.getData();
              handleProductDescription(data);
            }}
            type="classic"
          />
          <button className="ckEditorBtn"><img src={Images.ckBtnLogo} alt="logo" /> Generate AI Text </button>
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default ProductDetails;
