import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  galleryImg,
  galleryUpload,
  infoImg,
  manage,
  imgGallery1,
  modalCancel,
} from "../../../utilities/images";
import ProductDetails from "./ProductDetails";
import ProductImageAdd from "./productImageAdd";
import AddProductHeader from "./AddProductHeader";
import FixedPrice from "./fixedPrice";
import CustomPrice from "./customPrice";
import AddOptionsModal from "./addOptionsModal";
import CustomModal from "../../components/shared/CustomModal";
import AddAttributesModal from "./addAttributesModal";
import ProductPrice from "./productPrice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import { createProductApiAsync } from "../dashboard/dashboardSlice";
import SaleTaxModal from "./saleTaxModal";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Multiselect from "multiselect-react-dropdown";
import CreateOptionModal from "./createOptionModal";
import * as Images from "../../../utilities/images";
import CreateVariationsModal from "./createVariationsModal";

const AddProduct = (props) => {
  const history = useHistory();
  const backRoute = localStorage.getItem("backButtonRoute");
  const [productDetails, setProductDetails] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [costPrice, setcostPrice] = useState("");
  const { flag } = useParams();
  const [productDescription, setProductDescription] = useState("");
  const [skipPosDetails, setSkipPosDetails] = useState(false);
  const [imageDetails, setImageDetails] = useState({});
  const [prices, setPrices] = useState();
  const [activePos, setActivePos] = useState(false);
  const [activeOnlineStore, setActiveOnlineStore] = useState(false);
  const [activeBtoc, setActiveBtoc] = useState(false);
  const [activeBtob, setActiveBtob] = useState(false);
  const [inventoryOpening, setInventoryOpening] = useState("");
  const [inventoryReorderPoint, setInventoryReorderPoint] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [dimensions, setDimensions] = useState({
    weight: "",
    length: "",
    breadth: "",
    height: "",
  });
  console.log(dimensions, "dimensionsssss");
  const [type] = useState("physical");
  const [barCode, setBarCode] = useState("");
  const [unitType, setUnitType] = useState("per_unit");
  const [sku, setSku] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [selectedAttributesValue, setSelectedAttributesValue] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [singleImage, setSingleImage] = useState();
  console.log(singleImage, "singleImage");
  const [checkedOptionList, setCheckedOptionList] = useState([]);
  const [checkedValue, setCheckedValue] = useState([]);
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleWeightUnit = (e) => {
    setWeightUnit(e.target.value);
  };

  const handleUnitChange = (e) => {
    setUnitType(e.target.value);
  };

  const handleChangeJobrPos = () => {
    setActiveOnlineStore(!activeOnlineStore);
    if (activeBtoc) {
      setActiveBtoc(false);
    }
    if (activeBtob) {
      setActiveBtob(false);
    }
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else {
      setProductDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleChangeCostPrice = (e) => {
    setcostPrice(e.target.value);
  };

  const handleprices = (e) => {
    setPrices(e);
  };

  const handleAttributes = (e) => {
    setIngredients(e);
  };

  const handleSelect = (data) => {
    let allIds = data?.map((v) => v?.id);
    setAllAddressIds(allIds);
  };
  const handleBarcodeChange = (event) => {
    // Check if the input length is within the allowed limit (12 characters)
    if (event.target.value.length <= 14) {
      setBarCode(event.target.value);
    }
  };
  const handleSkuChange = (event) => {
    // Check if the input length is within the allowed limit (12 characters)
    if (event.target.value.length <= 14) {
      setSku(event.target.value);
    }
  };

  const handleOptions = (attributes, data, value) => {
    console.log(attributes, data, value, "handle options data");
    setOptionsList(attributes);
    setCombinations(data);
    setCheckedOptionList(new Array(attributes?.length).fill(false));
    setSelectedAttributesValue(value);
  };

  const handlechangeShippingOrPickup = (optionValue) => {
    if (deliveryOptions.includes(optionValue)) {
      setDeliveryOptions(
        deliveryOptions.filter((option) => option !== optionValue)
      );
    } else {
      setDeliveryOptions([...deliveryOptions, optionValue]);
    }
  };

  const handleSkipPOSDetails = (e) => {
    setSkipPosDetails(e.target.checked);
  };

  const handledimensions = (e) => {
    const { name, value } = e.target;
    let data = { ...dimensions, [name]: value ? value : 0 };
    setDimensions(data);
  };

  const uploadImage = (e) => {
    if (checkedValue.length <= 0) {
      toast.error("Please select checkbox");
    } else {
      const imageFile = e.target.files[0];
      let formData = new FormData();
      formData.append(`file`, imageFile);

      let params = {
        file: formData,
        token: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(imageUploadAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSingleImage(obj?.payload[0]?.filePath);
          const newImage = obj?.payload[0]?.filePath;
          replaceImage(optionsList, checkedValue, newImage);
          setLoading(false);
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
  };

  const handleCheckbox = (position) => {
    const updatedCheckedState = checkedOptionList.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedOptionList(updatedCheckedState);
    let data = updatedCheckedState.map((val, index) => {
      return val === true ? index : false;
    });
    let result = data?.filter((val) => val !== false);
    setCheckedValue(result);
    if (result.length == checkedOptionList.length) {
      setAllSelectChecked(true);
    } else {
      setAllSelectChecked(false);
    }
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked == false) {
      setAllSelectChecked(false);
      setCheckedOptionList(new Array(checkedOptionList?.length).fill(false));
      setCheckedValue([]);
    } else {
      setAllSelectChecked(true);
      setCheckedOptionList(new Array(checkedOptionList?.length).fill(true));
      const updatedCheckedState = checkedOptionList.map((item, index) => index);
      setCheckedValue(updatedCheckedState);
    }
  };

  function replaceImage(arr, indexes, url) {
    indexes.forEach((index) => {
      if (arr[index]) {
        arr[index].image = url;
      }
    });

    setOptionsList(arr);
    return arr;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Products";
  }, []);

  const handleSave = () => {
    let product_attribute = {
      name: productDetails?.productName,
      category_id: productDetails?.category_id?.toString(),
      sub_category_id: productDetails?.sub_category_id?.toString(),
      brand_id: (productDetails?.brand_id).toString(),
      service_id: productDetails?.service_id,
      description: productDescription,
      type: "physical",
      barcode: barCode,
      image: imageDetails?.image,
      images: imageDetails?.images,
      price: costPrice,
      quantity_unit: unitType,
      skip_detail_screen_in_pos: skipPosDetails,
      weight_unit: "lb",
      delivery_options: deliveryOptions,
      quantity: inventoryOpening,
      prices: prices,
    };
    console.log(product_attribute, "product_attributeRRRR");
    if (sku) {
      product_attribute = { ...product_attribute, sku: sku };
    }
    // if(deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")){
    if (deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) {
      product_attribute = { ...product_attribute, dimensions: dimensions };
    }
    if (inventoryReorderPoint) {
      product_attribute = {
        ...product_attribute,
        quantity_reorder_point: inventoryReorderPoint,
      };
    }
    if (ingredients?.length > 0) {
      product_attribute = { ...product_attribute, ingredients: ingredients };
    }
    if (optionsList?.length > 0) {
      product_attribute = { ...product_attribute, attributes: optionsList };
    }
    if (allAddressIds?.length > 0) {
      product_attribute = {
        ...product_attribute,
        seller_address_ids: allAddressIds,
      };
    }

    let result = formValidator(
      product_attribute,
      activePos,
      activeBtob,
      activeBtoc
    );
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success(obj?.msg);
          history.push(`${backRoute}`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  const handleModalChange = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        // const uniqueAddresses = [...new Set(obj?.payload?.map(entry => entry.format_address))];
        // console.log(uniqueAddresses,'uniqueAddresses');
        // const filteredData = obj?.payload?.filter(entry =>
        //     obj?.payload?.filter(item => item.format_address === entry.format_address).length > 1
        // );
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        // const addressCounts = obj?.payload?.reduce((acc, obj) => {
        //     acc[obj.format_address] = (acc[obj.format_address] || 0) + 1;
        //     return acc;
        // }, {});

        // const filtered = obj?.payload?.filter(obj => addressCounts[obj.format_address] > 1);
        // console.log(addressCounts,'addressCounts');
        // console.log(filtered,'filtered');
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="addProductManually">
      {/* Start FixedTopHeader */}
      <AddProductHeader
        flag={backRoute}
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}

                <ProductImageAdd imageData={(e) => handleImageDetails(e)} />

                <ProductDetails
                  setSelectedService={setSelectedService}
                  setServiceIdforAttributes={setServiceId}
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                />

                {/* End Uploaded ProductImg Area */}

                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Product cost price
                    </h2>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Cost of Good Sold
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => handleChangeCostPrice(e)}
                            // onChange={(e) => setcostPrice(e.target.value)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Unit
                        </label>
                        <select
                          autoComplete="new-password"
                          className="customSelect productSelect"
                          defaultValue={unitType}
                          onChange={(e) => handleUnitChange(e)}
                        >
                          <option value="">Per Item</option>
                          <option value="per_unit">per unit</option>
                          <option value="per_box">per box</option>
                          <option value="per_bag">per bag</option>
                          <option value="per_pound">per pound</option>
                          <option value="per_feet">per feet</option>
                          <option value="per_gallon">per gallon</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Product sale price
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input type="checkbox" />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            Use different sale price
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input type="checkbox" />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            On sale
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price would be same for all platforms
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Sale Price
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="text"
                            type="number"
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Profit
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="text"
                            type="number"
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Margin
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput"
                            placeholder="Select one"
                            name="text"
                            type="number"
                          />

                          <span className="dollrsign1_ usdTxt">%</span>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Product Tax
                          </h3>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            No Product tax setup yet!
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                            Product Stock & Locations
                          </h3>
                          <button className="commonBtn mt-4">
                            Manage Stock
                            <img
                              src={Images.upArrow}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Product Option
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "AddOptions",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Option
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Attributes
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button className="commonBtn mt-4">
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Attribute
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Order after out of stock
                          </h3>
                          <h3 className="fontSize14 txtLightBlue fw500 mb-0">
                            Let customers buy this product when it’s out of
                            stock.
                          </h3>
                        </div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group w-100 mb-4'>
                                                    <label className="subheaderHeading mb-2">Tax
                                                        <img src={infoImg} alt='InfoImg' className='img-fluid ms-3 cursorPoint' onClick={() => { setModalDetail({ show: true, flag: "saleTax" }); setKey(Math.random()); }} />
                                                    </label>
                                                    <select autoComplete="new-password" className='customform-control select' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                                                        <option value="per_unit">per unit</option>
                                                        <option value="per_box">per box</option>
                                                        <option value="per_bag">per bag</option>
                                                        <option value="per_pound">per pound</option>
                                                        <option value="per_feet">per feet</option>
                                                        <option value="per_gallon">per gallon</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div> */}

                {/* <div className="productDetailsContainer mt-3 ps-3 pe-3">
                <form className="row costPrice">
                  <div className="form-group w-100 mb-4">
                    <label className="subheaderHeading mb-2">Address</label>
                    <Multiselect
                      className="brandFilter customform-control"
                      displayValue="format_address"
                      options={allAddress}
                      hidePlaceholder={false}
                      placeholder="Select Address"
                      onSelect={handleSelect}
                      onRemove={handleSelect}
                      showCheckbox
                      
                    />
                  </div>
                </form>
              </div> */}

                {/* End CostPrice Area */}
                {/* Start JobrPos Area */}
                <ProductPrice
                  activePos={activePos}
                  activeBtoc={activeBtoc}
                  activeBtob={activeBtob}
                  activeOnlineStore={activeOnlineStore}
                  costPrice={costPrice}
                  prices={(e) => handleprices(e)}
                />

                {/* End JobrB2B Area */}
                {/*----- Start Options Area -----*/}
                {/* <div className="productDetailsContainer mt-3 ps-3 pe-3">
                  <h2 className="headingBlue mb-4">
                    Options
                    <img
                      src={infoImg}
                      alt="InfoImg"
                      className="infoIcon ms-3"
                    />
                  </h2>
                  <button
                    className="addOptions"
                    onClick={() => {
                      setModalDetail({ show: true, flag: "AddOptions" });
                      setKey(Math.random());
                    }}
                  >
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      className="me-2"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.25021 0C9.43145 7.6429e-06 9.60655 0.0656426 9.74314 0.184767C9.87973 0.303891 9.96856 0.468446 9.99321 0.648L10.0002 0.75L10.0012 8H17.2542C17.4442 8.00006 17.6272 8.07224 17.766 8.20197C17.9049 8.3317 17.9893 8.5093 18.0022 8.69888C18.0152 8.88846 17.9557 9.07589 17.8358 9.2233C17.7159 9.37071 17.5445 9.4671 17.3562 9.493L17.2542 9.5H10.0012L10.0032 16.75C10.0029 16.9399 9.93054 17.1227 9.80073 17.2613C9.67092 17.4 9.49334 17.4842 9.30383 17.4971C9.11433 17.5099 8.92702 17.4503 8.77972 17.3304C8.63242 17.2105 8.5361 17.0392 8.51021 16.851L8.50321 16.749L8.50121 9.5H1.25221C1.06219 9.49994 0.879272 9.42776 0.740421 9.29803C0.60157 9.1683 0.517139 8.9907 0.504187 8.80112C0.491235 8.61154 0.550728 8.42411 0.670645 8.2767C0.790563 8.12929 0.961963 8.0329 1.15021 8.007L1.25221 8H8.50221L8.50021 0.75C8.50021 0.551088 8.57923 0.360322 8.71988 0.21967C8.86054 0.0790175 9.0513 0 9.25021 0Z"
                        fill="#275AFF"
                      />
                    </svg>
                    Add Options
                  </button>

                  {optionsList.length > 0 ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <h4 className="deatilsHead mb-2 fw-normal">
                            Varients
                          </h4>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <input
                            type="checkbox"
                            className="checkBox"
                            id="view1"
                            checked={allSelectChecked}
                            onChange={(e) => {
                              handleSelectAllChange(e);
                            }}
                          />
                          <label htmlFor="view1" className="storeText">
                            {optionsList?.length} variations
                          </label>
                        </div>
                        <div className="col-md-6 text-end">
                          <div className="justify-content-end variantBtn">
                            <div className="imgFileBox me-3">
                              <input
                                type="file"
                                id="file"
                                accept="image/*"
                                className="file-input__input"
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                                required=""
                              />
                              <label
                                className="varientImgBtn"
                                htmlFor="file-input"
                              >
                                <img
                                  src={imgGallery1}
                                  alt=""
                                  className="me-2 img-fluid imgGalleryBtn"
                                />
                                Image Upload
                              </label>
                            </div>
                            <button
                              className="varientImgBtn"
                              onClick={() => {
                                setModalDetail({
                                  show: true,
                                  flag: "AddOptions",
                                });
                                setKey(Math.random());
                              }}
                            >
                              Manage
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table variantTables mt-3">
                          <tbody>
                            {optionsList?.map((item, index) => {
                              return (
                                <tr className="variantTable" key={index}>
                                  <td className="variantItems">
                                    <div className="d-flex align-items-center">
                                      <div className="rolecheckbox_">
                                        <input
                                          className="me-2"
                                          type="checkbox"
                                          id={`view1${index}`}
                                          name={item?.id}
                                          value={item?.id}
                                          checked={checkedOptionList[index]}
                                          onChange={() => handleCheckbox(index)}
                                        />
                                        <label
                                          className="contentheadtext_"
                                          htmlFor="view1"
                                        ></label>
                                      </div>
                                      <div className="d-flex">
                                        <div className="imgFileBox me-3">
                                          <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            accept="image/*"
                                            className="file-input__input"
                                            onChange={(e) => {
                                              uploadImage(e);
                                            }}
                                          />

                                          <label
                                            className=""
                                            htmlFor="file-input"
                                          >
                                            <span className="galleryCircle me-2">
                                              {loading &&
                                              checkedValue?.includes(index) ? (
                                                <span className="spinner-border spinner-border-sm"></span>
                                              ) : (
                                                <img
                                                  src={
                                                    item.image
                                                      ? item.image
                                                      : galleryImg
                                                  }
                                                  alt=""
                                                  className=""
                                                />
                                              )}
                                            </span>
                                          </label>
                                        </div>
                                        {item.attribute_values.map(
                                          (ele, index) => {
                                            return (
                                              <div
                                                className="variantItemsContent"
                                                key={index}
                                              >
                                                <p className="subtextDark_">
                                                  {ele?.attribute_value_name}
                                                </p>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="variantItems">
                                    <p className="textinner_">
                                      {item?.price ? item?.price : "$0.00"}
                                    </p>
                                  </td>

                                  <td className="variantItems">
                                    <p className="textinner_">
                                      {" "}
                                      {item?.upc ? item?.upc : "0"}
                                    </p>
                                  </td>
                                  <td className="variantItems">
                                    <div className=" d-flex align-items-center justify-content-between">
                                      <p className="textinner_">
                                        {item?.weight ? item?.weight : "0"}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                {/*----- End Options Area -----*/}
              </div>
            </div>
            {/* End ProductDetails Area */}
            <div className="col-md-4">
              {/* Start ProductAvailability Area */}
              <div className="outerArea">
                <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                  Product availability
                </h3>
                {/* <ul className="productToggleMenus">
                  <li className="productToggleItems">
                    <div className="form-check form-switch me-2 mb-0">
                      <input
                        type="checkbox"
                        //checked={true}
                        onChange={() => setActivePos(!activePos)}
                      />
                    </div>
                    <h3 className="fontSize14 fw500 txtDarkblue">
                      Physical store
                    </h3>
                    <h3 className="fontSize12 txtLightBlue">JOBR POS</h3>
                  </li>
                  <li className="productToggleItems">
                    <div className="form-check form-switch me-2 mb-0">
                      <input
                        type="checkbox"
                        onChange={() => handleChangeJobrPos()}
                      />
                    </div>
                    <h3 className="labelTxt">
                      <b>JOBR Online Store</b>
                    </h3>
                  </li>
                  {activeOnlineStore ? (
                    <>
                      <li className="productToggleItems ms-4">
                        <div className="form-check form-switch me-2 mb-0">
                          <input
                            type="checkbox"
                            value={false}
                            onChange={() => setActiveBtoc(!activeBtoc)}
                          />
                        </div>
                        <h4 className="storeText">JOBR B2C</h4>
                      </li>
                      <li className="productToggleItems pl-2 ms-4">
                        <div className="form-check form-switch  me-2 mb-0 ">
                          <input
                            type="checkbox"
                            value={false}
                            onChange={() => setActiveBtob(!activeBtob)}
                          />
                        </div>
                        <h4 className="storeText">JOBR B2B</h4>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul> */}
                <div className="d-flex align-items-start">
                  <img
                    src={Images.physicalStoreIcon}
                    alt="logo"
                    className="me-3"
                  />
                  <div>
                    <div className="innnerParent">
                      <div className="productSale_Parent p-0 m-0">
                        <input
                          type="checkbox"
                          onChange={() => setActivePos(!activePos)}
                        />
                      </div>
                      <div>
                        <h3 className="fontSize14 fw500 txtDarkblue">
                          Physical store
                        </h3>
                        <h3 className="fontSize12 txtLightBlue mb-0">
                          JOBR POS
                        </h3>
                      </div>
                    </div>
                    <div className="productAvailabilityChild mt-4">
                      <div class="form-group">
                        <input type="checkbox" id="css" />
                        <label for="css"></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                          Store 1
                        </h3>
                        <p className="fontSize12 txtLightBlue mb-0">
                          88070 Torey Gateway Colorado Springs, CO 80924
                        </p>
                      </div>
                    </div>
                    <div className="productAvailabilityChild mt-3">
                      <div class="form-group">
                        <input type="checkbox" id="second" />
                        <label for="second"></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                          Store 2
                        </h3>
                        <p className="fontSize12 txtLightBlue mb-0">
                          88070 Torey Gateway Colorado Springs, CO 80924
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="productAvail_Second d-flex align-items-start">
                  <img
                    src={Images.physicalStoreIcon}
                    alt="logo"
                    className="me-3"
                  />
                  <div>
                    <div className="innnerParent">
                      <div className="productSale_Parent p-0 m-0">
                        <input
                          type="checkbox"
                          onChange={() => setActivePos(!activePos)}
                        />
                      </div>
                      <div>
                        <h3 className="fontSize14 fw500 txtDarkblue">
                          JOBR Marketplace
                        </h3>
                        <h3 className="fontSize12 txtLightBlue mb-0">
                          Allow customers to book this service on JOBR
                          Marketplace app.
                        </h3>
                      </div>
                    </div>
                    <div className="productAvailabilityChild mt-4 align-items-center">
                      <div class="form-group">
                        <input type="checkbox" id="css1" />
                        <label for="css1"></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 ">Pickup</h3>
                      </div>
                    </div>
                    <div className="productAvailabilityChild mt-3 align-items-center">
                      <div class="form-group">
                        <input type="checkbox" id="second1" />
                        <label for="second1"></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 ">
                          Delivery
                        </h3>
                      </div>
                    </div>
                    <div className="productAvailabilityChild mt-3 align-items-center">
                      <div class="form-group">
                        <input type="checkbox" id="second2" />
                        <label for="second2"></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 ">
                          Shipping
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="outerArea mb-0">
                <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                  Marketing options
                </h3>
                <div className="createCoupen_container">
                  <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                    Create coupon
                  </h3>
                  <img src={Images.upArrow} alt="logo" />
                </div>
                <div className="createCoupen_container">
                  <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                    Promote this product
                  </h3>
                  <img src={Images.upArrow} alt="logo" />
                </div>
              </div>
              {/* <div className="outerArea mb-3">
                
                {type ? (
                  <form className="productDimensions productinput mt-4">
                    <div className="form-group mb-3">
                      <label className="deatilsHead mb-2 fw-normal">
                        Barcode(ISBN,UPC,GTIN,etc.)
                        <img
                          src={infoImg}
                          alt="InfoImg"
                          className="infoIcon ms-3"
                        />
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customform-control border"
                          onKeyDown={blockInvalidChar}
                          maxLength={14}
                          placeholder="888 1235647890"
                          name="weight"
                          type="number"
                          value={barCode}
                          onChange={handleBarcodeChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="deatilsHead mb-2 fw-normal">
                        SKU (Stock Keeping Unit)
                        <img
                          src={infoImg}
                          alt="InfoImg"
                          className="infoIcon ms-3"
                        />
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customform-control border"
                          placeholder="GO-123"
                          maxLength={14}
                          name="weight"
                          type="text"
                          value={sku}
                          onChange={handleSkuChange}
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div> */}
              {/* End ProductAvailability Area */}
              {/* Start Inventory Area */}
              {/* <div className="outerArea mb-3">
                <h3 className="subheaderHeading mb-4">Inventory</h3>
                <form className="inventory">
                  <div className="form-group mb-3">
                    <label className="deatilsHead mb-2 fw-normal">
                      Inventory-Opening
                      <img
                        src={infoImg}
                        alt="InfoImg"
                        className="infoIcon ms-3"
                      />
                    </label>
                    <div className="inputGroup">
                      <input
                        min="1"
                        onKeyDown={blockInvalidChar}
                        className="customform-control border"
                        placeholder=" Quantity "
                        type="number"
                        value={inventoryOpening}
                        onChange={(e) => setInventoryOpening(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="deatilsHead mb-2 fw-normal">
                      Inventory-Reorder Point
                      <img
                        src={infoImg}
                        alt="InfoImg"
                        className="infoIcon ms-3"
                      />
                    </label>
                    <div className="inputGroup">
                      <input
                        min="1"
                        onKeyDown={blockInvalidChar}
                        className="customform-control border"
                        placeholder=" Quantity reorder point"
                        type="number"
                        value={inventoryReorderPoint}
                        onChange={(e) =>
                          setInventoryReorderPoint(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div> */}
              {/* End Inventory Area */}
              {/* Start Attributes Area */}
              {/* <div className='outerArea mb-3'>
                                            <div className='d-flex justify-content-between'>
                                                <h3 className='subheaderHeading'>Attributes</h3>
                                                <Link to='#' className='linkTxt'
                                                    onClick={() => {
                                                        setModalDetail({ show: true, flag: "AddAttributesModal" });
                                                        setKey(Math.random());
                                                    }}
                                                >
                                                    Manage
                                                    <img src={manage} className='ms-3' alt='Manage' />
                                                </Link>
                                            </div>
                                            <ul className='attributesListMenus mt-3 '>
                                                {ingredients.length > 0 ? <>
                                                    {ingredients?.map((item, index) => {
                                                        return (
                                                            <li className='attributesListItems' key={index}>
                                                                <div className='col-6'>
                                                                    <span className='subtextSmall_ me-5 col-md-6'>{item.name}</span>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <span className='subtextDark_ col-md-6'>: {item.description}</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </> : ""}
                                            </ul>
                                        </div> */}
              {/* End Attributes Area */}
              {/* Start ShippingPickup Area */}
              {/* <div className="outerArea">
                <h3 className="subheaderHeading mb-4">Shipping or Pickup</h3>
                <ul className="productToggleMenus">
                  <li className="productToggleItems">
                    <div className="form-check form-switch me-2 mb-0">
                      <input
                        type="checkbox"
                        id="pickup"
                        value="pickup"
                        onChange={() => {
                          handlechangeShippingOrPickup("pickup");
                        }}
                      />
                    </div>
                    <h3 className="labelTxt">
                      <b>Pickup</b>
                    </h3>
                  </li>
                  <li className="productToggleItems">
                    <div className="form-check form-switch me-2 mb-0">
                      <input
                        type="checkbox"
                        id="delivery"
                        value="delivery"
                        onChange={() => {
                          handlechangeShippingOrPickup("delivery");
                        }}
                      />
                    </div>
                    <h3 className="labelTxt">
                      <b>Delivery</b>
                    </h3>
                  </li>
                  <li className="productToggleItems">
                    <div className="form-check form-switch me-2 mb-0">
                      <input
                        type="checkbox"
                        id="shipping"
                        value="shipping"
                        onChange={() => {
                          handlechangeShippingOrPickup("shipping");
                        }}
                      />
                    </div>
                    <h3 className="labelTxt">
                      <b>Shipping</b>
                    </h3>
                  </li>
                </ul>
                <p className="descriptionPara_ mt-4">
                  Enable this option if the product needs to be physically
                  delivered to customers either via shipping or by self-pickup.
                  If this product is a service or a downloadable item that
                  doesn’t require delivery, keep this option disabled.
                </p>
                
                {deliveryOptions.length > 0 ? (
                  <form className=" mt-4">
                    <div className="form-group mb-3 ">
                      <label className="deatilsHead mb-2 fw-normal">
                        Product weight
                        <img
                          src={infoImg}
                          alt="InfoImg"
                          className="infoIcon ms-3"
                        />
                      </label>
                      <div className="productWeight_">
                        <div className="col-lg-12">
                          <div className="row align-items-center ">
                            <div className="col-xl-12 col-lg-12 col-md-12 pe-0">
                              <div className="inputGroup">
                                <input
                                  className="customform-control weightPlace"
                                  placeholder="lb"
                                  name="weight"
                                  type="number"
                                  value={dimensions?.weight}
                                  onChange={(e) => {
                                    handledimensions(e);
                                  }}
                                />
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="deatilsHead mb-2 fw-normal">
                        Product Dimensions
                        <img
                          src={infoImg}
                          alt="InfoImg"
                          className="infoIcon ms-3"
                        />
                      </label>
                      <ul className="dimensionsGroup mb-3">
                        <li className="dimensionsGroupItems">
                          <label className="textinner_ mb-1">
                            <i>Length</i>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="length"
                            name="length"
                            aria-describedby="button-addon1"
                            value={dimensions?.length}
                            onChange={(e) => {
                              handledimensions(e);
                            }}
                          />
                        </li>
                        <li className="dimensionsGroupItems">
                          <label className="textinner_ mb-1">
                            <i>Width</i>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="width"
                            name="breadth"
                            aria-describedby="button-addon1"
                            value={dimensions?.breadth}
                            onChange={(e) => {
                              handledimensions(e);
                            }}
                          />
                        </li>
                        <li className="dimensionsGroupItems">
                          <label className="textinner_ mb-1">
                            <i>Height</i>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="height"
                            name="height"
                            aria-describedby="button-addon1"
                            value={dimensions?.height}
                            onChange={(e) => {
                              handledimensions(e);
                            }}
                          />
                        </li>
                        <li className="dimensionsGroupItems">
                          <span className="linkText">in</span>
                        </li>
                      </ul>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div> */}
              {/* End ShippingPickup Area */}
              {/* <div className='outerArea mt-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="skipPOS"
                                                            value={skipPosDetails}
                                                            onChange={(e) => { handleSkipPOSDetails(e) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Skip Details Screen in POS</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>
                                        </div> */}
            </div>

            {/* End ProductDetails Area */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddOptions"
            ? "addProductOptions"
            : modalDetail.flag === "createVariations"
            ? "createVariations"
            : modalDetail.flag === "AddAttributesModal"
            ? "addAttributesModal"
            : modalDetail.flag === "saleTax"
            ? "saleModalTax"
            : ""
        }
        size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
        child={
          modalDetail.flag === "AddOptions" ? (
            <AddOptionsModal
              id={serviceId}
              close={() => handleOnCloseModal()}
              options={(attributes, data, value) =>
                handleOptions(attributes, data, value)
              }
              combinations={combinations}
              selectedAttributesValue={selectedAttributesValue}
              handleModalChange={(e) => handleModalChange(e)}
            />
          ) : modalDetail.flag === "createVariations" ? (
            <CreateVariationsModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "AddAttributesModal" ? (
            <AddAttributesModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "saleTax" ? (
            <SaleTaxModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "createVariant" ? (
            <CreateOptionModal
              selectedService={selectedService}
              serviceId={serviceId}
              close={() => handleOnCloseModal()}
              handleModalChange={(e) => handleModalChange(e)}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "AddOptions" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">Add Options</h3>
                {/* <span
                  className="crossIcon"
                  onClick={() => handleOnCloseModal()}
                >
                  <i className="las la-times" aria-hidden="true"></i>
                </span> */}
                <div className="d-flex align-items-center">
                  <button className="modalDiscardBtn">Discard</button>
                  <button
                    className="modalNextBtn modalDiscardBtn active"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "createVariations",
                      });
                      setKey(Math.random());
                    }}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "createVariations" ? (
              <>
                <div className="d-flex align-items-center">
                  <button className="modalBackBtn"><img src={Images.blueBackArrow} alt="logo"/></button>
                  <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                    Create variations
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="modalNextBtn modalDiscardBtn active createVariationBtn"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "createVariations",
                      });
                      setKey(Math.random());
                    }}
                  >
                    Create 5 variations
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "saleTax" ? (
              <>
                <h4 className="modalHeading_">Sales tax</h4>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            ) : (
              <>
                <h4 className="modalHeading_">Add custom attribute</h4>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  className="modal_cancel"
                >
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddProduct;
