import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import CustomModal from "../../components/shared/CustomModal";
import ColorPickerModal from "./colorPickerModal";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete, Chip, TextField } from "@mui/material";

const AddOptionsModal = () => {
  const [activePlan, setActivePlan] = useState("list");

  const [color, setColor] = useColor("rgb(86 30 203)");
  const handleTogglePlan = (plan) => {
    setActivePlan(plan);
  };

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };

  const tags = ["Tag1", "Tag2", "Tag3"];

  const [values, setValues] = useState({
    productTags: ["Tag1", "Tag3"],
  });

  const handleProducttags = (event, newValue) => {
    // Ensure unique tags and update the state
    setValues((prev) => ({
      ...prev,
      productTags: [...new Set(newValue)],
    }));
  };

  return (
    <>
      <p className="fontSize16 txtDarkblue">
        Add a custom set of options to an item to create variations. For
        example, a size option set can create variations small, medium, and
        large.
      </p>
      <div className="modalOptionBox">
        <div className="row">
          <div className="col-md-9">
            <div className="form_group pt-0">
              <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                Option set name
                <img src={Images.labelImg} alt="logo" className="ms-2" />
              </label>

              <div className="inputGroup">
                <select name="select" id="select" className="commonSelect">
                  <option value="Size">Size</option>
                  <option value="Color">Color</option>
                  <option value="Weight">Weight</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-3 pe-0">
            <div className="pricingPara">
              <label className="fontSize14 txtDarkblue mb-2 fw500">
                Show in product page as
              </label>
              <ul className="planTabs_ m-0">
                <li
                  className={`planttabslist ${
                    activePlan === "list" ? "active" : ""
                  }`}
                  onClick={() => handleTogglePlan("list")}
                >
                  <img
                    src={Images.listLogo}
                    alt="logo"
                    className="optionModal_ToggleLogo"
                  />
                  List
                </li>
                <li
                  className={`planttabslist ${
                    activePlan === "color" ? "active" : ""
                  }`}
                  onClick={() => handleTogglePlan("color")}
                >
                  <img
                    src={Images.colorLogo}
                    alt="logo"
                    className="optionModal_ToggleLogo"
                  />
                  Color
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form_group">
              <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                Display name
                <img src={Images.labelImg} alt="logo" className="ms-2" />
              </label>

              <div className="inputGroup">
                <input
                  className="customInput txtDarkblue"
                  placeholder="Color"
                  name="text"
                  type="text"
                />
              </div>
            </div>
          </div>

          {activePlan == "list" && (
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                  Options
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup autocompleteParent">
                  <Autocomplete
                    multiple
                    freeSolo
                    // popupIcon={<img alt="dropdownicon" src={Images.calendar} />}
                    getOptionLabel={(option) => option}
                    id="tags-filled"
                    options={tags}
                    value={values.productTags}
                    onChange={handleProducttags}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            variant="outlined"
                            label={option}
                            size="small"
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Type options"
                        className="custom-filled-input"
                      />
                    )}
                  />
                </div>
              </div>
              <p className="pressEnterTxt pt-2 ps-4">
                Press <span className="fw700">Enter</span> or add{" "}
                <span className="fw700">a comma</span> after each choice.
              </p>
            </div>
          )}

          {activePlan == "color" && (
            <div className="col-md-12">
              <div
                className="form_group"
                onClick={() => handleOpenModal("colorPickerModal")}
              >
                <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                  Color options
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup">
                  <div className="customInput txtDarkblue colorPickerParent">
                    <div className="showcolorBox">
                      <div className="d-flex align-items-center">
                        <div className="selectedColor"></div>
                        <h3 className="colorName fontSize14 fw500 ms-2 mb-0">
                          Black
                        </h3>
                      </div>
                      <img src={Images.lightBlueCross} alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
              <p className="pressEnterTxt pt-2 ps-4">
                Press <span className="fw700">Enter</span> or add{" "}
                <span className="fw700">a comma</span> after each choice.
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        <button className="addOptionSetBtn w-100">
          <i className="fa-regular fa-plus addOptionPlusIcon me-2"></i>Add
          Option Set
        </button>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "colorPickerModal" ? "colorPickerModal" : ""}
        // size={modalDetail.flag === "colorPickerModal" ? "xl" : "lg"}
        child={
          modalDetail.flag === "colorPickerModal" ? (
            <ColorPickerModal onCloseModal={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default AddOptionsModal;
