import React, { useState } from "react";
import * as Images from "../../../utilities/images";

const CreateVariationsModal = () => {
  const [dropdownState , setDropdownState] = useState(false);
  const handleDropDown=()=>{
    setDropdownState(true);
  }
  return (
    <>
      <p className="fontSize16 txtDarkblue mt-4">
        The variations below will be created from your options.
      </p>
      <div className="row">
        <div className="col-md-3">
          <div className="form_group pt-4">
            <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
              Group By
              <img src={Images.labelImg} alt="logo" className="ms-2" />
            </label>

            <div className="inputGroup">
              <select name="select" id="select" className="commonSelect">
                <option value="Size">Size</option>
                <option value="Color">Color</option>
                <option value="Weight">Weight</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="modalOptionBox mt-4">
        <div>
          <div className="row">
          <div className="col-md-5">
              <div className="productAvailabilityChild align-items-center mb-4">
                <div class="form-group">
                  <input type="checkbox" id="check1" />
                  <label for="check1"></label>
                </div>

                <h3 className="fontSize14  fw500 txtLightBlue mb-0">
                  All Options
                </h3>
              </div>
            </div>
            <div className="col-md-5">
              <h3 className="fontSize14  fw500 txtLightBlue mb-0">
                Cost Price
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="productAvailabilityChild align-items-center">
                <div class="form-group">
                  <input type="checkbox" id="check1" />
                  <label for="check1"></label>
                </div>
                <div>
                  <h3 className="fontSize14  fw500 txtDarkblue mb-0 pb-1">
                  Blue
                  </h3>
                  <p className="fontSize12 txtLightBlue mb-0">
                    2 Options <i className="fa-solid fa-angle-down ms-2" onClick={()=>{
                      handleDropDown()
                    }}></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5"><h3 className="fontSize14  fw500 txtLightBlue mb-0">
            $90.00
              </h3></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVariationsModal;
